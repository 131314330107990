@import '~styles/colors'

.timepicker-overlay
  z-index: 1001 !important
.timepicker__header
  background: $primary !important
.timepicker-button
  color: $primary !important
.clock-face__number > span.active
  background-color: $primary !important
  color: white !important
.clock-face__clock-hand
  background-color: $primary !important
  &:after
    background-color: $primary !important
.clock-face__clock-hand_minute:before
  border: 4px solid $primary !important
.timepicker__actions
  background: $card-grey !important
