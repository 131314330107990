@import "~styles/colors"

.platform-layout, .admin-layout, .context-overlay

  .mat-drawer-container
    background: $sidenav !important

  .sidenav-topbar
    text-transform: uppercase
    letter-spacing: 2px

  .mat-sidenav, .sidenav-topbar mat-toolbar, mat-sidenav.context-sidenav mat-toolbar
    background: $sidenav !important
    z-index: 1
    color: $sidenav-text
    .mat-icon-button:hover
      background: $sidenav-dark !important

  .mat-sidenav, .dialog-sidenav
    background: $sidenav !important
    border-right: 1px solid $sidenav-dark !important

  .sidenav-content, .context-sidenav
    .mat-subheading-1
      color: white
    .mat-caption
      font-size: 10px
      margin-bottom: 5px
      letter-spacing: 1px
      color: $sidenav-text-darker
    .mat-body-1
      color: $sidenav-text-dark
    .header
      margin: 0
      color: white

    .sidenav-button
      background: $sidenav-dark !important
      color: $sidenav-text
      &:hover
        background: $sidenav-darker !important
    
    .mat-button[disabled], .mat-raised-button[disabled], .mat-icon-button[disabled]
      cursor: not-allowed !important

    .sidenav-box
      padding: 10px
      background: $sidenav-light
      border-radius: 7px
      p.header
        color: white
      P.mat-body-1
        font-size: 13px
      
