@import '../rtl';

@mixin td-divider-utilities() {
  // deprecated
  mat-divider {
    &[matInset] {
      @include rtl(margin-left, 72px, 0);
      @include rtl(margin-right, 0, 72px);
    }
    &[mat-inset] {
      @include rtl(margin-left, 72px, 0);
      @include rtl(margin-right, 0, 72px);
    }
  }
}
