mat-grid-tile
  position: relative
  .tile-text
    width: auto
    position: absolute
    bottom: 15px
    top: 15px
    left: 15px
    right: 15px
    overflow: hidden
  h4.tile-title
    font-weight: bold
    font-size: 1.3em
    margin: 5px 0 3px 0
    padding: 0
    &.nowrap
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
  .title-link
    cursor: pointer
    transition: color ease .2s
    &:hover
      color: $link-color
  .tile-modified-info
    margin-bottom: 7px
    color: darken(white, 22)
    font-size: 0.7em
  .tile-description
    overflow: hidden
    color: grey
    margin-bottom: 12px
    font-size: 0.9em
    line-height: 1.4em
  .tile-tags
    height: 32
    max-height: 32px
    overflow: hidden
  .theme-tag
    cursor: pointer
    padding: 4px 7px
    background: darken(white, 40)
    color: white
    border-radius: 2px
    font-size: 12px
    font-weight: normal
    &:hover
      background: #176BCC  
    &.selected
      background: #176BCC
    &.empty
      background: darken(white, 20)
      cursor: default
      pointer-events: none

mat-grid-tile.text
  overflow: hidden
  .mat-figure
    box-sizing: border-box
    display: block
    padding: 10px 16px

mat-grid-tile.title
  h4.mat-title
    margin: 3px 0 10px 0