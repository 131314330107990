@import "~styles/colors"
@import "~styles/helpers"

td-layout-nav
  mat-toolbar.mat-primary
    z-index: 9
    font-family: "Helvetica Neue" !important
    font-weight: 200 !important
    font-size: 20px !important
    letter-spacing: 1px
  .link
    cursor: pointer
    padding: 5px
  .link:hover
    color: $link-color-hover
  .link.subsection:not(.active)
    opacity: 0.4
  .link.subsection:not(.active):hover
    opacity: 1
  .link-divider
    cursor: default
    margin: 0 15px

.admin-app-container td-layout-nav
  .mat-toolbar.mat-primary
    background: $dark-blue
    color: white

.app-container td-layout-nav
  .mat-toolbar.mat-primary
    background: $topbar-platform
    color: $sidenav

mat-toolbar.topbar
  position: relative
  z-index: 8

.font-topbar
  letter-spacing: 1px
  font-family: "Roboto", "Helvetica Neue", sans-serif
  font-weight: 400
  font-size: 14px
  i.fa-filter
    margin-right: 8px
  
mat-toolbar
  .mat-button-tab
    @include matTransition
    border-radius: 0 !important
    padding-top: 15px !important
    padding-bottom: 9px !important
    border-bottom: 4px solid $topbar !important
    color: lighten(black, 40) !important
    &:hover
      border-bottom: 4px solid $primary !important
  .mat-button-tab.selected
    border-bottom: 4px solid darken($primary, 10) !important
    color: black !important
  .mat-button-tab[disabled]
    border-bottom: none !important
    color: silver !important
  .mat-icon-button:hover
    background: #DADADA !important

.topbar-sort-select
  margin: 0 10px
  mat-select
    border-radius: 3px !important
    font-family: Roboto, "Helvetica Neue", sans-serif
    font-size: 14px
    font-weight: 500
    letter-spacing: 0
    text-transform: none
    background: darken($topbar, 1) !important
    border: 1px solid darken($topbar, 6)
    min-width: 150px
    max-width: 165px
    padding: 0 10px
    &:hover
      background: #DADADA !important
    &.mat-select-disabled
      opacity: 0.6
      pointer-events: none