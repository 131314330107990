.trigger-summary-actual
  margin-bottom: 15px
  margin-right: 15px
  .grp
    margin-left: 15px
    padding-left: 25px
  .grp.valid, .con.valid
    border-left: 1px solid $valid
  .grp.invalid, .con.invalid
    border-left: 1px dashed $invalid
  .cat
    border-radius: 16px
    .cat-name
      padding: 4px
      font-size: 11px
      letter-spacing: 1px
      text-transform: uppercase
      .label
        border-radius: 16px
        color: white
        display: block
        padding: 6px 12px
  .cat:not(:last-child)
    margin-bottom: 5px  
  .cat.valid
    .label
      background: $valid
  .cat.invalid
    .label
      background: $invalid  

  .con
    padding: 5px 0
    margin-top: 0
    line-height: 26px
    margin-left: 20px
    padding-left: 30px
    font-size: 0.7em
  .con:first-of-type
    margin-top: 5px
    padding-top: 0
  .con:last-of-type
    margin-bottom: 5px
    padding-bottom: 0  
  .con-label, .con-operator, .con-value
    margin-right: 7px
  .con-label
    font-weight: bold
    // margin-right: 5px
  .con-operator
    // margin-right: 5px
    color: black 
  .con-value
    // margin-right: 5px
    .highlight
      border-radius: 3px
      display: block
      padding: 0px 6px
      font-weight: bold

  .grp-relation, .cat-relation, .con-relation
    position: relative
    text-align: center
    z-index: 1
    font-size: 0.6em
  
  .grp-relation, .cat-relation
    background: $valid
    border-radius: 15px
    font-weight: thin
    height: 26px
    line-height: 28px
    width: 26px
    color: white

  .grp-relation.or-relation
    left: 2px
    margin: -4px auto -4px 1px

  .grp-relation.and-relation
    left: 9px
    height: 13px
    width: 13px

  .cat-relation.or-relation
    left: 8px
    margin: -15px auto -10px 0

  .cat-relation.and-relation
    left: 16px
    margin: -7px auto -7px 0
    height: 9px
    width: 9px

  .con-relation.or-relation
    color: white
    width: 20px
    height: 20px
    border-radius: 10px
    line-height: 22px
    margin: -8px auto -8px 11px

  .con-relation.and-relation
    width: 9px
    height: 9px
    border-radius: 4.5px
    line-height: 13px
    margin: -4px auto -4px 16px

  .con + .con-relation
    background: $valid
  
  .con.single
    margin-left: 0
    padding: 5px 8px
