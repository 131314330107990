@import 'colors'

// all directions spacing helpers
$spaces: 0, 5, 10, 15, 20, 25, 30, 35, 40, 45
$directions: top, right, bottom, left
@each $space in $spaces
  @each $direction in $directions
    .space-#{$direction}-#{$space}
      margin-#{$direction}: #{$space}px !important

// $pixels: 2, 3, 4, 5, 6, 7
// @each $pixel in $pixels
//   .card-rounded-#{$pixel}
//     -webkit-border-radius: #{$pixel}px !important
//     -moz-border-radius: #{$pixel}px !important
//     border-radius: #{$pixel}px !important
//     overflow: hidden !important

@mixin matTransition
  transition: all .2s ease-in-out
  -moz-transition: all .2s ease-in-out
  -webkit-transition: all .2s ease-in-out

@keyframes rotate
  0%
    transform: none
  100%
    transform: rotate(360deg)

// make keyframes that tell the start state and the end state of our object
@-webkit-keyframes fadeIn 
  from 
    opacity: 0 
  to 
    opacity: 1 
@-moz-keyframes fadeIn 
  from 
    opacity: 0
  to 
    opacity: 1
@keyframes fadeIn
  from
    opacity: 0
  to
    opacity: 1

.fade-in
  opacity: 0
  -webkit-animation: fadeIn ease-in 1
  -moz-animation: fadeIn ease-in 1
  animation: fadeIn ease-in 1
  -webkit-animation-fill-mode: forwards  
  -moz-animation-fill-mode: forwards
  animation-fill-mode: forwards
  -webkit-animation-duration: 0.7s
  -moz-animation-duration: 0.7s
  animation-duration: 0.7s

@mixin card-shadow
  -webkit-box-shadow: 0px 4px 10px -6px rgba(0,0,0,0.75) !important
  -moz-box-shadow: 0px 4px 10px -6px rgba(0,0,0,0.75) !important
  box-shadow: 0px 4px 10px -6px rgba(0,0,0,0.75) !important

@function makeLongShadow($color)
  $val: 0px 0px $color
  @for $i from 1 through 200
    $val: #{$val}, #{$i}px #{$i}px #{$color}
  @return $val

.long-shadow
  text-shadow: makeLongShadow( rgba(0,0,0,.01) )

.text-link
  color: $link-color !important
  cursor: pointer
  &:hover
    color: $link-color-hover !important
    border-bottom: 1px solid $link-color-hover

.disabled-content
  pointer-events: none
  opacity: 0.6

.scrolling-content
  height: calc(100vh - 160px)
  overflow-x: hidden
  overflow-y: auto
  padding: 16px

.badge
  background: darken(white, 50)
  color: white
  font-size: smaller
  padding: 3px 6px

.env-tag
  color: white
  font-size: 10px
  padding: 5px 7px
  border-radius: 8px
  text-transform: uppercase
.env-tag.dev
  background: grey
.env-tag.prod
  background: red

ul.no-bullet-list
  margin-left: 0
  padding-left: 15px

.link-pointer:hover
  cursor: pointer

.status-badge
  border-radius: 16px
  color: white
  font-size: 10px
  letter-spacing: 1px
  padding: 6px 12px
  text-align: center
  text-transform: uppercase
.status-badge.valid
  background: $valid
.status-badge.invalid
  background: $invalid
.status-badge.pending
  background: darken(white, 40)

.setting
  min-height: 40px
  .primary-label
    color: black
    font-weight: bold
  .secondary-label
    margin-top: 5px
    color: grey
    font-weight: normal
    font-size: smaller
  mat-select
    background: #F5F5F5
    border-radius: 3px
    padding: 12px 16px
  mat-form-field
    max-width: 100px
    text-align: right

mat-slide-toggle.setting
  height: auto
  line-height: inherit
  position: relative
  width: 100%
  .mat-slide-toggle-bar
    position: absolute
    right: 0
  &.singlerow
    line-height: 40px
  &.pad
    box-sizing: border-box
    .mat-slide-toggle-bar
      right: 16px


.setting-select
  background: #F5F5F5
  border-radius: 3px
  max-width: 100%
  padding: 12px 16px

.ngx-toastr.in-progress
  background-image: none
  i.progress-icon
    font-size: 20px
    left: 20px
    position: absolute