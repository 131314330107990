// mixin definition ; sets LTR and RTL within the same style call
// @see https://css-tricks.com/almanac/properties/d/direction/

@mixin rtl($prop, $value, $rtl-value) {
  #{$prop}: $value;

  html[dir='rtl'] & {
    #{$prop}: $rtl-value;
    unicode-bidi: embed;
  }
  body[dir='rtl'] & {
    #{$prop}: $rtl-value;
    unicode-bidi: embed;
  }
  [dir='rtl'] & {
    #{$prop}: $rtl-value;
    unicode-bidi: embed;
  }

  bdo[dir='rtl'] {
    direction: rtl;
    unicode-bidi: bidi-override;
  }
  bdo[dir='ltr'] {
    direction: ltr;
    unicode-bidi: bidi-override;
  }
}
