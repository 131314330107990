@mixin td-content-utilities() {
  md-content,
  [md-content],
  .md-content,
  mat-content,
  [mat-content],
  .mat-content {
    display: block;
    position: relative;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    &[md-scroll-y],
    &.mat-scroll-y {
      overflow-y: auto;
      overflow-x: hidden;
    }
    &[md-scroll-x],
    &.mat-scroll-x {
      overflow-x: auto;
      overflow-y: hidden;
    }
    // For iOS allow disabling of momentum scrolling
    // @see issue #2640.
    &.autoScroll {
      -webkit-overflow-scrolling: auto;
    }
  }

  // Overall app layout height
  mat-sidenav-container {
    & > md-content,
    & > [md-content],
    & > .md-content,
    & > mat-content,
    & > [mat-content],
    & > .mat-content {
      height: 100%;
      overflow: hidden;
    }
  }
}
