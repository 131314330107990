@import "~styles/colors"

.overlay-title
  margin: 0 8px 40px 8px
  position: relative
  h1, h2
    font-family: 'Roboto', sans-serif
    margin: 5px 0
    font-weight: 300
    strong
      font-weight: 500
    i
      font-size: 80%
  h1
    color: $sidenav
    font-size: 2em
  h2
    color: rgba(0,0,0,.6)
    font-size: 1.5em
  p
    margin: 0 0 30px 0
    color: grey

.overlay-title.secondary
  margin-bottom: 20px
  margin-top: 40px