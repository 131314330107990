// This is file for flow styles used across different modules:
// admin campaigns / platform campaigns / platform reports
  
@import "~styles/colors"

.state-icon
  align-items: center
  background: grey
  border-radius: 40px
  box-shadow: 0 0 7px darken(white, 50)
  color: white
  cursor: default
  display: flex
  height: 80px
  justify-content: center
  margin: 15px auto
  width: 80px
  &.medium
    border-radius: 35px
    box-shadow: none
    height: 70px
    width: 70px
  &.small
    border-radius: 25px
    box-shadow: none
    height: 50px
    width: 50px
  &.draft
    background: $draft
    &.invalid
      background: $invalid
  &.active
    background: $active
  &.running
    background: $active
    .material-icons
      animation: rotate 6s linear infinite
  &.paused
    background: $paused
    &.invalid
      background: $invalid
  &.completed
    background: $completed

.state-icon-text
  font-size: 12px
  letter-spacing: 1px
  color: grey
  
.flow-icon
  width: 140px
  height: 140px
  line-height: 140px
  margin: 15px auto 0 auto
  border-radius: 70px
  font-size: 72px !important
  overflow: hidden
  color: white
  text-align: center
  i
    font-size: 72px
    opacity: .80 !important
  svg
    opacity: .80 !important

.campaign-status-icon
  margin-right: 6px
  .mat-icon
    font-size: 20px
    &.draft
      color: $draft
    &.active, &.running
      color: $active
    &.paused
      color: $paused
    &.completed
      color: $completed
.campaign-status-label
  border-radius: 10px
  color: white
  display: inline-block
  font-size: 10px
  letter-spacing: 1px
  padding: 5px 10px
  text-align: center
  text-transform: uppercase
  &.draft
    background: $draft
  &.active, &.running
    background: $active
  &.paused
    background: $paused
  &.completed
    background: $completed