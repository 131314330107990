.p-slider
  background: #E0E1E0
  border: none
  margin-top: 6px
  position: relative
  text-align: left

.p-slider .p-slider-handle
  background: #01579B
  border-radius: 10px
  -webkit-border-radius: 10px
  -moz-border-radius: 10px
  cursor: default
  height: 20px !important
  outline: none
  position: absolute
  touch-action: none
  -ms-touch-action: none
  width: 20px !important
  z-index: 2 !important

.p-slider .p-slider-range
  background: #01579B
  background-position: 0 0
  border: none
  position: absolute
  z-index: 1
  font-size: .2em
  display: block
  border: 0

p-slider.disabled
  .p-slider, .p-slider-range
    background: #E0E1E0 !important
  .p-slider-handle
    display: none

.p-slider-horizontal
  height: .8em
.p-slider-horizontal .p-slider-handle
  top: -.25em
  margin-left: -.6em
.p-slider-horizontal .p-slider-range
  top: 0
  height: 100%
.p-slider-horizontal .p-slider-range-min
  left: 0
.p-slider-horizontal .p-slider-range-max
  right: 0

.p-slider-animate .p-slider-handle
  transition: left .3s

.p-slider .p-slider-handle.ui-state-active
  background-position: -17px 0 

.p-slider-horizontal
  height: 2px 

.p-slider-horizontal .p-slider-handle
  top: -9px !important
  margin-left: -.6em 

.p-slider-horizontal .p-slider-range
  top: 0
  height: 100% 

.p-slider-horizontal .p-slider-range-min
  left: 0 

.p-slider-horizontal .p-slider-range-max
  right: 0 

.p-slider-vertical
  width: .8em
  height: 100px 

.p-slider-vertical .p-slider-handle
  left: -.1em !important
  margin-left: 0
  margin-bottom: -.6em 

.p-slider-vertical .p-slider-range
  left: 0
  width: 100% 

.p-slider-vertical .p-slider-range-min
  bottom: 0 

.p-slider-vertical .p-slider-range-max
  top: 0 
