@import "~styles/colors"

$icon-small-container-size: 24px
$icon-small-size: 18px


.buttons
  button
    margin-right: 5px
  button:last-child
    margin-right: 0

.mat-raised
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)

.mat-icon-button.mat-icon-button-small
  width: $icon-small-container-size
  height: $icon-small-container-size
  line-height: $icon-small-container-size
  .mat-icon, .mat-icon-button i
    line-height: 15px
  .mat-icon
    width: $icon-small-size
    height: $icon-small-size
  .material-icons
    font-size: $icon-small-size

.normal-tag
  color: black
  font-size: 14px
  font-weight: bold
  margin-right: 2px

.mat-small-button
  height: 24px !important
  line-height: 24px !important
  font-size: 12px !important
  font-weight: 300 !important

.mat-load-button
  position: relative
  div
    color: black
    visibility: hidden
    position: absolute
    top: 0
    bottom: 0
    text-align: center
    width: 100%

.mat-load-button.active
  color: #BBBCBB
  div
    visibility: visible

.logic-block
  position: relative
  ih-help
    .help-button
      transform: scale(0,0)
      
  .card-header-link
    max-width: calc(100% - 30px)
  .card-header-link + ih-help
    position: absolute
    right: 10px
    top: 8px
    .help-button
      color: #616161
      height: 2em
      margin: 0
      width: 2em
      .mat-icon
        font-size: 1.5em !important

.logic-block:hover
  ih-help
    .help-button
      transform: scale(1,1)