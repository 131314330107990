/* this file will be extracted to main dist folder and is imported in index.html */
/* This file is for setting global styles  */

// 3rd party component styles
@import "~@angular/material/prebuilt-themes/indigo-pink.css"
@import "~@covalent/core/common/platform.css"
@import './ngprime-rangeslider'
@import '~primeng/resources/primeng.min.css'
@import '~ngx-toastr/toastr.css'

@import './campaigns'
@import './colors'
@import './dialogs'
@import './themes'
@import './fonts'
@import './helpers'
@import './card'
@import './tiles'
@import './buttons'
@import './sidenav'
@import './timepicker'
@import './topbar'
@import './trigger_summary'
@import './overlay'

mat-sidenav-content
  background: $page !important

.fill-space
  // fills remaining horizontal space, by using flexbox.
  // Every toolbar row uses a flexbox row layout.
  flex: 1 1 auto

.full-width
  width: 100%

.td-layout-manage-list-sidenav
  width: 256px !important

.mat-icon.fa, .mat-icon.fas, .mat-icon.far, .mat-icon.fab
  font-size: 1.3em
  padding-top: 5px !important

.no-bottom-padding
  .mat-form-field-wrapper
    padding-bottom: 0 !important
