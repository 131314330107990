@import '../variables';

@function makelongshadow($color) {
  $val: 0 0 $color;
  @for $i from 1 through 200 {
    $val: #{$val}, #{$i}px #{$i}px #{$color};
  }
  @return $val;
}

@mixin longshadow($color) {
  text-shadow: makelongshadow($color);
}

$color: rgba(0, 0, 0, 0.01);

@mixin td-card-utilities() {
  html {
    .mat-card {
      padding: 0;
      margin: 8px;
      mat-card-header {
        height: auto;
      }
      [matCardAvatar] {
        font-size: $mat-card-header-size;
        line-height: $mat-card-header-size;
        height: $mat-card-header-size;
        width: $mat-card-header-size;
        margin: 16px 0 0 15px;
        border-radius: 50%;
      }
      .mat-card-image {
        width: 100%;
      }
      .mat-card-image,
      .mat-card-lg-image,
      .mat-card-md-image,
      .mat-card-sm-image,
      .mat-card-title-group {
        margin: 0;
      }
      mat-card-title {
        padding-top: $padding;
        padding-left: $padding;
        padding-right: $padding;
      }
      mat-card-subtitle {
        padding-left: $padding;
        padding-right: $padding;
      }
      mat-card-content {
        padding: $padding;
      }
      &,
      & .mat-card {
        & .mat-card-actions {
          padding: $padding / 2;
          margin: 0;
        }
      }
      .mat-card-actions:last-child {
        margin-bottom: 0;
        padding-bottom: 8px;
      }
      .mat-divider.relative {
        position: relative;
      }
    }
    &[dir='rtl'] {
      .mat-card-title-group {
        .mat-card-image,
        .mat-card-lg-image,
        .mat-card-md-image,
        .mat-card-sm-image {
          &:last-child {
            border-top-left-radius: $mat-card-radius;
            border-bottom-left-radius: $mat-card-radius;
          }
        }
      }
      .mat-card {
        .mat-card-image {
          &:first-child {
            border-top-left-radius: $mat-card-radius;
            border-top-right-radius: $mat-card-radius;
          }
        }
        .mat-card-lg-image,
        .mat-card-md-image,
        .mat-card-sm-image {
          &:first-child {
            border-top-right-radius: $mat-card-radius;
          }
        }
      }
    }
    &:not([dir='rtl']) {
      .mat-card-title-group {
        .mat-card-image,
        .mat-card-lg-image,
        .mat-card-md-image,
        .mat-card-sm-image {
          &:last-child {
            border-top-right-radius: $mat-card-radius;
            border-bottom-right-radius: $mat-card-radius;
          }
        }
      }
      .mat-card {
        .mat-card-image {
          &:first-child {
            border-top-left-radius: $mat-card-radius;
            border-top-right-radius: $mat-card-radius;
          }
        }
        .mat-card-lg-image,
        .mat-card-md-image,
        .mat-card-sm-image {
          &:first-child {
            border-top-left-radius: $mat-card-radius;
          }
        }
      }
    }
  }

  .mat-card-colored {
    &[href],
    &[ng-reflect-href] {
      &:hover {
        cursor: pointer;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      }
      &:active {
        box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2), 0 10px 14px 1px rgba(0, 0, 0, 0.14),
          0 4px 18px 3px rgba(0, 0, 0, 0.12);
        & mat-toolbar {
          background-color: rgba(255, 255, 255, 0.2);
          background-position: -100% 100%;
        }
      }
      & mat-toolbar {
        background-size: 200% 100%;
        background-image: linear-gradient(to right, transparent 50%, rgba(255, 255, 255, 0.2) 50%);
        transition: background-position 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.1s, color 0.5s ease 0s,
          background-color 0.5s ease;
      }
    }
    & mat-toolbar {
      height: 148px;
      border-radius: 2px 2px 0 0;
      overflow: hidden;

      mat-icon,
      .mat-icon {
        opacity: 0.65;
        color: white;
        font-size: 8rem;
        @include longshadow(darken($color, 5%));
      }
    }
  }
}
