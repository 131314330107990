@import "~styles/colors"

.ih-dialog
  .mat-dialog-container
    margin: 0
    min-height: 400px
    min-width: 300px
    overflow: hidden
    padding: 0
    position: relative

    .mat-dialog-title, .mat-dialog-content, .mat-dialog-actions
      margin: 0

    .mat-dialog-title
      background: $blue
      color: darken(white, 5)
      font-weight: 300
      height: 64px
      line-height: 64px
      padding: 0 16px
    .mat-dialog-title.warn
      background: $red
    .mat-dialog-title.plain
      background: whitesmoke
      color: black

    .mat-dialog-content
      box-sizing: border-box
      height: calc(100% - 120px)
      max-height: unset
      min-height: 280px
      overflow-x: hidden
      overflow-y: auto
      padding: 16px
      position: relative
      .dialog-sidenav
        background: whitesmoke
        padding: 0 8px
        .mat-toolbar
          padding: 0 8px
        .filter-list
          .mat-form-field
            color: $sidenav
          .mat-form-field-underline
            background-color: $sidenav
          .mat-form-field-ripple
            background-color: $sidenav !important
      .dialog-sidenav.white, .dialog-sidenav.page
        height: 100%
        .select-list-filter
          mat-selection-list
            .all-checkbox
              color: $sidenav
            .mat-list-option
              color: $sidenav
      .dialog-sidenav.white
        background: white
        .mat-toolbar
          background: white
      .dialog-sidenav.page
        background: $page
        .mat-toolbar
          background: $page

      .dialog-tabs
        height: 100%
        .mat-tab-header
          background: whitesmoke
          .mat-tab-label
            min-width: 100px
        .mat-tab-body-wrapper
          height: 100%
          .mat-tab-body-content
            box-sizing: border-box
            padding: 24px 16px 48px
      .dialog-tabs-replacer
        display: flex
        align-items: center
        justify-content: center
        height: 100%
        text-align: center
        mat-icon
          display: block
          font-size: 8rem
    .mat-dialog-content.no-actions
      height: calc(100% - 64px)
      min-height: 336px
    .mat-dialog-content.no-padding
      padding: 0
    .mat-dialog-actions
      background: darken(white, 3)
      border-top: 1px solid darken(white, 8)
      height: 56px
      padding: 0 8px
    .mat-dialog-actions.transparent
      background: transparent
      border-top: none

.ih-dialog.minimal
  .mat-dialog-container
    min-height: unset
    min-width: unset
    .mat-dialog-content
      min-height: unset !important

.ih-dialog.ih-dialog-sidebar
  .mat-drawer-container
    height: 100%
  .mat-drawer-inner-container
    overflow-x: hidden
    
.ih-dialog.stepper
  .mat-stepper-horizontal
    height: calc(100% - 64px)
  .mat-horizontal-stepper-header
    pointer-events: none !important
    .mat-step-icon
      opacity: 0.75
      transform: scale(0.8)
      transition: transform .3s, opacity .3s
    .mat-step-icon.mat-step-icon-selected
      opacity: 1
      transform: scale(1.2)
    .mat-step-label
      font-weight: 300
      opacity: 0.75
      transition: font-weight .3s, opacity .3s
    .mat-step-label.mat-step-label-selected
      font-weight: 500
      opacity: 1
  .mat-horizontal-content-container
    height: calc(100% - 72px)
    padding: 0 !important
  .mat-horizontal-stepper-content[aria-expanded=true]
    height: 100%
  .mat-dialog-content
    height: calc(100% - 56px) !important

.ih-dialog .mat-dialog-content .wizard-content
  box-sizing: border-box
  max-height: 100%
  max-width: 100%
  overflow-x: hidden
  overflow-y: auto
  padding: 15px
  width: 480px

.ih-dialog .mat-dialog-content.scrollable
  box-sizing: border-box
  overflow-x: hidden
  overflow-y: auto

.ih-dialog.ih-dialog-80vh .mat-dialog-content.scrollable
  max-height: calc(80vh - 128px)
