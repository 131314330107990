@import "~styles/colors"

$card-radius: 4px
  
.card-wrapper
  z-index: 100
  position: relative
  button.remove-button
    z-index: 200
    position: absolute
    top: -12px
    right: -6px
    height: 25px
    width: 25px
    mat-icon
      position: absolute
      top: 4px
      left: 4px

mat-card
  mat-card-content
    .mat-headline
      margin-bottom: 0
  mat-card-content.content-header
    background: $card-grey
    margin: 0

.mat-card
  -webkit-box-shadow: 0px 4px 10px -6px rgba(0,0,0,0.75) !important
  -moz-box-shadow: 0px 4px 10px -6px rgba(0,0,0,0.75) !important
  box-shadow: 0px 4px 10px -6px rgba(0,0,0,0.75) !important

  .mat-card-title
    .mat-headline, .mat-title
      margin-bottom: 0
    .mat-caption
      padding-bottom: 15px

  .mat-card-content, .mat-card-title
    .mat-title, .mat-title
      margin-bottom: 0

.card-rounded, .card-rounded-overflow
  -webkit-border-radius: $card-radius !important
  -moz-border-radius: $card-radius !important
  border-radius: $card-radius !important
  
.card-rounded
  overflow: hidden !important

.card-clickable, .card-clickable-flat
  border-radius: $card-radius !important
  cursor: pointer
.card-clickable
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.15), 0 4px 7px 1px rgba(0, 0, 0, 0.12), 0 1px 12px 1px rgba(0, 0, 0, 0.09) !important
  &:hover
    box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.2), 0 6px 10px 2px rgba(0, 0, 0, 0.14), 0 2px 16px 2px rgba(0, 0, 0, 0.12) !important
.card-clickable-flat
  box-shadow: none !important
  &:hover
    box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.15), 0 4px 7px 1px rgba(0, 0, 0, 0.12), 0 1px 12px 1px rgba(0, 0, 0, 0.09) !important

.card-header, .toolbar-header
  width: 100%
  letter-spacing: 1px !important
  font-size: 11px !important
  color: $sidenav-text-darker
  text-transform: uppercase

.card-header:after
  display: block
  content: ''
  padding-bottom: 5px
  margin-bottom: 5px
.card-header-link
  cursor: pointer
  color: $link-color
.card-header-link:hover
  color: $link-color-hover !important
.card-header-link:after
  border-bottom: solid 2px $link-color-hover
  transform-origin: 0% 50%
  transform: scaleX(0)
  transition: transform 150ms ease-in-out
.card-header-link:hover:after 
  transform: scaleX(1)
  transform-origin: 0% 50%
.card-header-link.fromRight:after
  transform-origin: 100% 50%
    
.mat-card.valid
  border-left: 4px solid $valid
.mat-card.invalid
  border-left: 4px solid $invalid

.mat-card.configuration-card
  border-radius: 7px !important
  margin-bottom: 30px
  overflow: hidden
  .mat-card-content
    padding: 32px 16px 24px
    margin: 0
  .mat-card-actions
    background: darken(white, 5)