@import "~styles/colors"

.header
  margin-top: 0
  padding-top: 0

.last-text
  margin-bottom: 0
  padding-bottom: 0

.mat-title
  font-family: "Roboto", "Helvetica Neue", sans-serif
  font-weight: 400

.font-awesome-icons
  display: inline-flex
  align-items: center
  justify-content: center
  vertical-align: middle

.material-icons
  display: inline-flex
  align-items: center
  justify-content: center
  vertical-align: middle


// material icons sizes
$pixels: 12, 14, 18, 24, 36, 48, 56, 60, 72
@each $pixel in $pixels
  .material-icons.mat-#{$pixel}
    font-size: #{$pixel}px !important
  .fa-#{$pixel}
    font-size: #{$pixel}px !important

.is-bold
  font-weight: bold

.is-normal
  font-weight: normal

.is-smaller
  font-size: smaller

.is-small
  font-size: small

.header-caption
  margin: 0 0 16px 0
  text-transform: uppercase
  letter-spacing: 1px
  font-size: 12px
  font-weight: bold

