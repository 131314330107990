// https://coolors.co/f5f5f5-59c9a5-5b6c5d-3b2c35-2a1f2d


$blue: #01579B
$green: #59C9A5
$red: #D65523
$light-grey: #E0E0E0
$dark-grey: #898989
$dark-grey-hover: #707070
$dark-blue: #01579B

$primary: $blue
$page: #D7D8D7

$valid: $green
$invalid: $red

$draft: $page
$active: $valid
$paused: darken($valid, 15)
$completed: #808080

$topbar-platform: white
$topbar: #F5F5F5
$topbar-menu-active: darken($topbar, 10)
$topbar-menu-hover: darken($topbar, 5)

$sidenav: #3B2C35
$sidenav-light: lighten($sidenav, 15)
$sidenav-dark: darken($sidenav, 10)
$sidenav-darker: darken($sidenav, 20)
$sidenav-empty-field: $sidenav-light

$sidenav-button: $sidenav-light
$sidenav-button-hover: lighten($sidenav-light, 10)
$sidenav-button-active: white

$sidenav-text: #C9C9C9
$sidenav-text-dark: darken($sidenav-text, 10)
$sidenav-text-darker: darken($sidenav-text, 18)
$sidenav-text-hover: white
$sidenav-text-active: white
$sidenav-active-tag: white
$sidenav-active-tag-text: $sidenav

$tag: #DFE0DF
$tag-text: $sidenav-dark
$tag-selected: #176BCC
$tag-selected-text: white

$border-color: #E0E0E0
$card-grey: darken(white, 4)
$card-cyan: #E6F0F0

$button-hover-grey: #DADADA
$white-icon-grey: #D7D8D7

$link-color: $blue
$link-color-hover: darken($link-color, 10)

.sidenav-light
  color: $sidenav-light !important
  
.green, .valid-green
  color: $green !important

.red, .invalid-red
  color: $red !important

.bgc-sidenav-light
  background: $sidenav-light
  color: $sidenav-text

.bgc-primary
  background: $primary
  color: white

.bgc-green
  background: $valid
  color: white

.bgc-red
  background: $invalid
  color: white

.bgc-sidenav-dark
  background: $sidenav-dark !important

.bgc-page
  background: $page !important

$nav-button-color: #00838F
