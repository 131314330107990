@import 'theme-functions';

// Teradata UI Platform
// ------------------------------
$navigation-drawer-width: 320px !default;
$padding: 16px;
$margin: $padding;
$step-circle: 24px;

// Typography
// ------------------------------
$font-family: 'RobotoDraft', 'Roboto', 'Helvetica Neue', sans-serif !default;
$font-size: 10px;

$display-4-font-size-base: rem(11.2) !default;
$display-3-font-size-base: rem(5.6) !default;
$display-2-font-size-base: rem(4.5) !default;
$display-1-font-size-base: rem(3.4) !default;
$headline-font-size-base: rem(2.4) !default;
$title-font-size-base: rem(2) !default;
$subhead-font-size-base: rem(1.6) !default;

$body-font-size-base: rem(1.4) !default;
$caption-font-size-base: rem(1.2) !default;

// Layout
$baseline-grid: 8px !default;
$layout-breakpoint-xs: 600px !default;
$layout-breakpoint-sm: 960px !default;
$layout-breakpoint-md: 1280px !default;
$layout-breakpoint-lg: 1920px !default;
$layout-gutter-width: ($baseline-grid * 2) !default;

// Typography
$mat-body-font-size-base: rem(1.4) !default;

// App bar variables
$app-bar-height: 64px;

// card header variables
$mat-card-header-size: 40px;
$mat-card-radius: 2px;

// Icons
$icon-size: rem(2.4);

// Toast
$toast-height: $baseline-grid * 3 !default;
$toast-margin: $baseline-grid * 1 !default;

// Whiteframes
$whiteframe-shadow-z1: 0 2px 5px 0 rgba(0, 0, 0, 0.26) !default;
$whiteframe-zindex-z1: 1 !default;
$whiteframe-shadow-z2: 0 8px 17px rgba(0, 0, 0, 0.2) !default;
$whiteframe-zindex-z2: 2 !default;
$whiteframe-shadow-z3: 0 17px 50px rgba(0, 0, 0, 0.19) !default;
$whiteframe-zindex-z3: 3 !default;
$whiteframe-shadow-z4: 0 16px 28px 0 rgba(0, 0, 0, 0.22) !default;
$whiteframe-zindex-z4: 4 !default;
$whiteframe-shadow-z5: 0 27px 24px 0 rgba(0, 0, 0, 0.2) !default;
$whiteframe-zindex-z5: 5 !default;

// Z-indexes
$z-index-tooltip: 100 !default;
$z-index-dialog: 80 !default;
$z-index-toast: 90 !default;
$z-index-bottom-sheet: 70 !default;
$z-index-scroll-mask: 65 !default;
$z-index-sidenav: 60 !default;
$z-index-backdrop: 50 !default;
$z-index-toolbar: 30 !default;
$z-index-fab: 20 !default;
$z-index-progress-circular: 2 !default; // Used to fix animation bug in Chrome

// Easing Curves

// The default animation curves used by material design.
$mat-linear-out-slow-in-timing-function: cubic-bezier(0, 0, 0.2, 0.1) !default;
$mat-fast-out-slow-in-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !default;
$mat-fast-out-linear-in-timing-function: cubic-bezier(0.4, 0, 1, 1) !default;

$ease-in-out-curve-function: cubic-bezier(0.35, 0, 0.25, 1) !default;

$swift-ease-out-duration: 0.4s !default;
$swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;
$swift-ease-out: all $swift-ease-out-duration $swift-ease-out-timing-function !default;

$swift-ease-in-duration: 0.3s !default;
$swift-ease-in-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2) !default;
$swift-ease-in: all $swift-ease-in-duration $swift-ease-in-timing-function !default;

$swift-ease-in-out-duration: 0.5s !default;
$swift-ease-in-out-timing-function: cubic-bezier(0.35, 0, 0.25, 1) !default;
$swift-ease-in-out: all $swift-ease-in-out-duration $swift-ease-in-out-timing-function !default;

$swift-linear-duration: 0.08s !default;
$swift-linear-timing-function: linear !default;
$swift-linear: all $swift-linear-duration $swift-linear-timing-function !default;
