@import '~@angular/material/theming'
@import '~@covalent/core/theming/all-theme'

@include mat-core()


// $primary: mat-palette($mat-blue, 700)
$primary: mat-palette($mat-light-blue, 900)
$accent:  mat-palette($mat-grey, 500, A100, A400)
$warn:    mat-palette($mat-red, 500)

$theme: mat-light-theme($primary, $accent, $warn)

@include angular-material-theme($theme)
@include covalent-theme($theme)

@mixin theme-color($theme)
  // Parse the theme and create variables for each color in the pallete
  $primary: map-get($theme, primary)
  $accent: map-get($theme, accent)
  $warn: map-get($theme, warn)

  $overview: mat-palette($mat-light-green, 400)
  $profiles: mat-palette($mat-red, 600)

  // Create theme specfic styles
  .bgc-primary
    background-color: mat-color($primary)
  .c-primary
    color: mat-color($primary)
  .bgc-accent
    background-color: mat-color($accent)
  .bgc-warn
    background-color: mat-color($warn)
  .bgc-overview
    background-color: mat-color($overview)


@include theme-color($theme)

.bgc-button-on-grey
  background: #E0E0E0
